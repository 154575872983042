﻿.carousel {
    min-height: 330px;

    @media (min-width: $screen-sm-min) {
        min-height: 430px;
    }

    &__slide{

        text-align: center;

        img{ //may need to remove if slick slide -PG
            max-width:100%;
            height:auto;
        }
    }

    &__inner {
        overflow: hidden;
        left: 0;
        top: 0;
        width: 100%;

        .slick-dotted.slick-slider{

            margin-bottom:0px;
        }
    }
    
    &-control {

        top: 50%;
        height: 80px;
        width: 50px;
        color: $c-brand-primary;

        @include opacity(1);

        transform: translateY(-50%);
        z-index: 1;
        transition:all ease-in 0.3s;

        @media (max-width: $screen-xs-max) {
            transform: scale(0.6);
            top:21%;
        }

        &:hover {
            opacity:0.5;
            color: $c-brand-primary;
        }

        &:after {
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            border-color: #0e33a0;
            border-width: 2px;
            border-left: solid;
            border-top: solid;
            position: absolute;
            top: 20px;
        }

        &.left {
            left: 40px;
            background-image: none;
            pointer-events: auto;

            @media (max-width: $screen-xs-max) {
                left:14px;
            }

            &:after {
                transform: rotate(-45deg);
                left: 15px;
            }
        }

        &.right {
            right: 40px;
            background-image: none;
            pointer-events: auto;

             @media (max-width: $screen-xs-max) {
                right:14px;
            }

            &:after {
                transform: rotate(135deg);
                right: 15px;
            }
        }
    }

    .slick {
        &-dotted.slick-slider {
            @media (max-width: $screen-xs-max) {
                margin-bottom: 0;
            }
        }
        &-prev, &-next {
            height: 80px;
            width: 80px;
            background-size: contain;
            z-index: 100;

            @media (max-width: $screen-xs-max) {
                bottom: -21px;
                top: auto;
                height: 50px;
                width: 50px;
                background-size: 60% !important;
            }

            &:before {
                display: none;
            }

            &:hover {
                opacity: .75;
            }

            &.slick-disabled {
                display: none !important;
            }
        }

        &-prev {
            left: 25px;
            

            @media (max-width: $screen-xs-max) {
                left: 20px;
            }
        }

        &-next {
            right: 25px;
            

            @media (max-width: $screen-xs-max) {
                right: 20px;
            }
        }

        &-dots {
            top: 350px;

            li{

                &:before{

                    display:none;
                }
            }

            @media (min-width: $screen-md-min) {
                bottom: 14px;
                top: auto;
            }

            @media (max-width: $screen-sm-max) {
                top: auto;
                bottom: 14px;
            }

            li button:before {
                width: 13px;
                height: 13px;
                content: "";
                opacity: .5;
                border: 2px solid $c-brand-primary;
                border-radius: 50%;
            }

            .slick-active button:before {
                opacity: 1;
            }
        }
    }
}

//General uniform Carousel styling
.slick-arrow{

        $squarespace: 60px;

        z-index:1;        
        background-repeat:no-repeat;
        width: 38px;
        height: 55px;
        background-size:27%;
        background-position: 50% 18%;


            @media(min-width:$screen-lg-min){
                    
                width: 60px;
                height: 60px;

            }

        &:before,
        &:after{

            background:transparent;
            color:$c-brand-primary;
        }     

        &.slick-next,
        &.slick-prev{

            @include fa-icon($fa-var-play, 32px);

            
            transform:auto;
            

            &:hover,
            &:focus{

                @include fa-icon($fa-var-play, 32px);
                background-repeat:no-repeat;
                background-size:27%;
                background-position: 50% 18%;
                opacity:0.6;
            }
        }

        &.slick-prev{

            transform: rotate(180deg);
            left:-20px;
            top: 45%; //covers flip

             @media(min-width:$screen-lg-min){

                left:-20px;

             }
        }

        
        &.slick-next{

            right:-3px;


            @media(min-width:$screen-lg-min){

               // right:-75px; 
            }            
            
        }

        &.slick-disabled{

            display:none !important;
        }
        
}