button{

	border:0px none;
}

.btn{

	padding:10px 15px;
	display:inline-block;
	text-decoration: none;
	font-weight:700;
	font-size:17px;
	position:relative;
	text-align: center;
	transition:all ease-in 0.3s;
	background:$c-brand-primary;
	border-radius:1em;
	color:#fff;
	width: 100%;

	@media(min-width: $screen-md-min){

		width: auto;
		font-size: 20px;
	}


	&:hover{

		text-decoration: none;
		color:#fff;
	}


	&--grey{

		background:$c-grey;
		color:#fff;

		&:hover{

            color:#fff;
            text-decoration: none;

		}
	}

	&--blue{

		background:$c-brightblue;

	}

	&--yellow{

		background:$c-yellow;

	}

	&--photos{

		background:$c-yellow;
		padding-right:70px;
		font-size:21px;
		margin-bottom: 20px;


		@include fa-icon($fa-var-images, 26px);
		
		&:before{
			
			position:absolute;
			right:15px;
			top:13px;

		}
		
	}

	&--return{

		@include fa-icon($fa-var-arrow-left, 26px);
		padding-left:40px;
		margin-bottom:10px;
		margin-top:5px !important;

		@media(min-width: $screen-md-min){

			padding-left:76px;
		}

		&:before{
			
			position:absolute;
			left:15px;
			top:12px;

		}
	}

	&--nextPage{

		@include fa-icon($fa-var-arrow-right, 26px);

		padding-right:76px;
		margin-bottom:10px;
		margin-top:5px !important;

		&:before{
			
			position:absolute;
			right:15px;
			top:12px;

		}
	}

	&--nextStep, 
	&--topStep{

		background:$c-brand-dark-grey;
		padding-right:70px;
		font-size:23px;
		z-index:1;
		cursor: pointer;

		@include fa-icon($fa-var-play, 18px);

		&:before{
			
			position:absolute;
			right: 19px;
    		top: 19px;
			z-index:2;
			color:$c-brand-primary;

			transform: rotate(90deg);

			@media(min-width: $screen-md-min){

				right:18px;
				top:18px;
				transform: rotate(90deg);
			}

		}

		&:after{

			content:"";
			width: 40px;
    		height: 40px;
			border-radius:1em;
			background:#fff;
			position:absolute;
			z-index:0;
			right:6px;
			top:6px;
		}
	}

	&--topStep{

		background:$c-brand-primary;

		margin-bottom:20px;

		@media(min-width: $screen-lg-min){

			margin-bottom:0px;
		}

		&:before{

			transform: rotate(-90deg);
		}

		&:hover,
		&:active,
		&:focus{

			text-decoration: none;
			color:#fff;
		}
	}

	

}

.heroBanner .btn--startTour{

			background:$c-brand-dark-grey;
			display: block;
			width:100%;
			border-radius:0px;
			padding: 13px 0;
    		font-size: 30px;

    		@media(min-width: $screen-md-min){

    			width:300px;
    			position:absolute;
    			top: 342px;
    			left:60px;
    			border-radius:2em;

    			display:none; //based on UX feedback
    		}

    		@media(min-width: 1024px){

				top:166px;
				transform:scale(0.8);
				left:40px;

    		}



			i{
				
				display:inline-block;
				width: 50px;
    			position: relative;
    			margin: 0px 37px;
				@media(min-width: $screen-md-min){

					margin: 0 0px 0 26px;
				}


				@include fa-icon($fa-var-play, 30px);
				&:before{
			
					position:absolute;
					right: 24px;
		    		top: -21px;
					z-index:2;
					color:$c-brand-primary;

					transform: rotate(90deg);

				}

				&:after{

					content:"";
					width: 64px;
		    		height: 64px;
					border-radius:50%;
					background:#fff;
					position:absolute;
					z-index:0;
					right:6px;
					top: -42px;
				}
					

			}
			
			//hides original position from --nextStep
			&:before,
			&:after{

				display:none;
			}

		
	}