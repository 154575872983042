%img-crisp {
    //image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    //-ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
}

//SVG's

%buttonArrowWhite {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='33' viewBox='0 0 26 33'%3E%3Cpath data-name='Shape 1196 copy 7' d='M2 0h7l17 17-16 16H2l16-16zm.5 14A2.5 2.5 0 1 1 0 16.5 2.5 2.5 0 0 1 2.5 14z' fill='%23fff' fill-rule='evenodd'/%3E%3C/svg%3E");
}

%buttonArrowRed {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='33' viewBox='0 0 26 33'%3E%3Cpath data-name='Shape 1196 copy 7' d='M2 0h7l17 17-16 16H2l16-16zm.5 14A2.5 2.5 0 1 1 0 16.5 2.5 2.5 0 0 1 2.5 14z' fill='%23d8312c' fill-rule='evenodd'/%3E%3C/svg%3E");
}

%sliderArrow {
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='96.5' height='95' viewBox='0 0 96.5 95'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill: none;stroke: %23FFF;stroke-miterlimit: 10%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EArtboard 1%3C/title%3E%3Ccircle class='cls-1' cx='47.13' cy='47.6' r='37'/%3E%3Cpath class='cls-1' d='M41.63 29.6l18 18-16.75 16.75'/%3E%3C/svg%3E");
}

%stripeOverlay {
    background-image: linear-gradient(45deg, rgba(255,255,255,0.2) 33.33%, rgba(0,0,0,0.2) 33.33%, rgba(0,0,0,0.2) 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 83.33%, rgba(0,0,0,0.2) 83.33%, rgba(0,0,0,0.2) 100%);
    background-size: 4.24px 4.24px;
}

%offSymbolWhite {
    background-image: url("/Assets/images/off-logo-white.svg"); //Amended to be cross browser compat - article stating the pros and cons of base64 encoding bg svgs https://css-tricks.com/probably-dont-base64-svg/ - RM
}

%offSymbolDark {
    background-image: url("/Assets/images/off-logo-dark.svg"); //Amended to be cross browser compat - article stating the pros and cons of base64 encoding bg svgs https://css-tricks.com/probably-dont-base64-svg/ - RM
}

%diagonalThreeStripe {
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='759.96' height='756.29' viewBox='0 0 759.96 756.29'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill: %23b8d7f0%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EArtboard 2%3C/title%3E%3Cpath class='cls-1' d='M256.67 244.53l118.22 118.22L493.14 481l118.18 118.18 118.24 118.24 24.11 24.11 6.29-6.3-24.1-24.1-118.24-118.24L499.43 474.7 381.19 356.45 262.97 238.24 144.74 120.01 26.54 1.81l-6.29 6.3L138.44 126.3l118.23 118.23zM249.28 251.92L367.5 370.14l118.25 118.25 118.19 118.18 118.23 118.24 24.11 24.11 6.25-6.26-24.1-24.1-118.24-118.24L492 482.13 373.76 363.88 255.54 245.67 137.31 127.44 19.11 9.24l-6.25 6.25 118.19 118.2 118.23 118.23zM745.14 750.04l-24.11-24.11-118.22-118.22-118.2-118.2-118.22-118.22-118.25-118.25-118.22-118.22L11.74 16.64l-6.26 6.25 118.19 118.19L241.88 259.3l118.25 118.25 118.22 118.21 118.21 118.21 118.21 118.22 24.11 24.1 6.26-6.25z'/%3E%3C/svg%3E");
}

%homeIconGreen {
    background-image: url("data:image/svg+xml,%3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 111.06 97.19'%3E%3Ctitle%3EArtboard 3%3C/title%3E%3Cpath d='M103.61 43.66L91 33.12V9.62a.65.65 0 0 0-.67-.62H74.17a.65.65 0 0 0-.66.65v8.91L55.06 3.11a.64.64 0 0 0-.42-.11.7.7 0 0 0-.42.15L5.69 43.66a.67.67 0 0 0-.2.73.64.64 0 0 0 .61.42h7.55v47.8a.67.67 0 0 0 .66.66h45.52V61.49a.64.64 0 0 1 .64-.65h16.62a.66.66 0 0 1 .66.66v31.77h17.46a.66.66 0 0 0 .66-.66v-47.8h7.33a.62.62 0 0 0 .6-.42.65.65 0 0 0-.19-.73M33.35 76.21a.66.66 0 0 1-.65.66h-5.64a.65.65 0 0 1-.64-.66v-5.63a.64.64 0 0 1 .64-.64h5.64a.65.65 0 0 1 .65.64zm0-9.1a.66.66 0 0 1-.65.66h-5.64a.66.66 0 0 1-.64-.66v-5.62a.66.66 0 0 1 .64-.66h5.64a.65.65 0 0 1 .65.65zm9.2 9.27a.66.66 0 0 1-.66.66h-5.62a.66.66 0 0 1-.65-.66v-5.63a.66.66 0 0 1 .65-.66h5.62a.66.66 0 0 1 .66.66zm0-9.27a.66.66 0 0 1-.66.66h-5.62a.66.66 0 0 1-.65-.66v-5.62a.66.66 0 0 1 .65-.66h5.62a.64.64 0 0 1 .66.65z' fill='%23009a49'/%3E%3C/svg%3E");
}

%homeIconWhite {
    background-image: url("data:image/svg+xml,%3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 111.06 97.19'%3E%3Ctitle%3EArtboard 3%3C/title%3E%3Cpath d='M103.61 43.66L91 33.12V9.62a.65.65 0 0 0-.67-.62H74.17a.65.65 0 0 0-.66.65v8.91L55.06 3.11a.64.64 0 0 0-.42-.11.7.7 0 0 0-.42.15L5.69 43.66a.67.67 0 0 0-.2.73.64.64 0 0 0 .61.42h7.55v47.8a.67.67 0 0 0 .66.66h45.52V61.49a.64.64 0 0 1 .64-.65h16.62a.66.66 0 0 1 .66.66v31.77h17.46a.66.66 0 0 0 .66-.66v-47.8h7.33a.62.62 0 0 0 .6-.42.65.65 0 0 0-.19-.73M33.35 76.21a.66.66 0 0 1-.65.66h-5.64a.65.65 0 0 1-.64-.66v-5.63a.64.64 0 0 1 .64-.64h5.64a.65.65 0 0 1 .65.64zm0-9.1a.66.66 0 0 1-.65.66h-5.64a.66.66 0 0 1-.64-.66v-5.62a.66.66 0 0 1 .64-.66h5.64a.65.65 0 0 1 .65.65zm9.2 9.27a.66.66 0 0 1-.66.66h-5.62a.66.66 0 0 1-.65-.66v-5.63a.66.66 0 0 1 .65-.66h5.62a.66.66 0 0 1 .66.66zm0-9.27a.66.66 0 0 1-.66.66h-5.62a.66.66 0 0 1-.65-.66v-5.62a.66.66 0 0 1 .65-.66h5.62a.64.64 0 0 1 .66.65z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.img-responsive {
    width: 100% !important;
}
