#section-banner {
    //allows anchor to return to top and counters fixed nav height
    display: block;
    content: " ";
    margin-top: -130px;
    height: 132px;
    visibility: hidden;
    pointer-events: none; //in case of overlap with content links
    position: relative;
    z-index: -1;
}

.heroBanner {
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    z-index: 0;
    border-top: 5px solid $c-yellow;
    min-height: 450px;



    @media(min-width: 667px) {
        min-height: 520px;
    }

    @media(min-height: $screen-md-min) {
        min-height: auto;
    }

    &__image {
        background-repeat: no-repeat;
        background-size: cover;
        // background-image:url(#{$imgPath}/top-map.svg);
        background-position: 50%;
        width: 100%;
        padding-top: 100%;
        position: absolute; //mobile split header
        @media(min-width: $screen-md-min) {
            height: 100%;
        }
    }

    &__svgWrap {
        //IE
        width: 90%;
        height: 0;
        height: 190px;
        position: relative;
        margin: 0 auto;
        z-index: 1;

        @media(min-width: 667px) {
            height: 295px;
        }

        @media(min-width: $screen-sm-min) {
            width: 100%;
            height: 450px;
        }

        @media(min-width: $screen-md-min) {
            height: 640px;
        }

        @media(min-width: $screen-lg-min) {
            height: 900px;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            display: block;
            width: 100%;

            @media(min-width: $screen-md-min) {
                width: 90%;
            }

            a {
                text-decoration: none;
                transition: all ease-in 0.3s;

                &:hover {
                    opacity: 0.6;
                }

                &:focus,
                &:active {
                    color: #fff;
                }
            }
        }
    }


   
    &__title {
        text-align: center;

        @media(min-width: $screen-md-min) {
            left: 60px;
            top: 30px;
            position: absolute;
        }
    }

    h1 {
        font-size: 36px;
        font-weight: 900;
        color: $c-brand-primary;
        line-height: 1em;
        margin-bottom: 0px;

        @media(min-width: $screen-md-min) {
            font-size: 77px;
        }
    }

    h2 {
        color: $c-skyblue;

        @media(min-width: $screen-md-min) {
            font-weight: 900;
            font-size: 35px;
            color: $c-brand-dark-grey;
            margin-bottom:10px;
        }
    }

    &__textBox {
        background: $c-brand-dark-grey;
        padding: 30px;
        color: #fff;

        h2 {
            font-weight: 300;
        }

        p {
            font-size: 16px;
        }
    }

    .btn {
        margin-top: 23px;
        float: right;
        width: 80%;
        position: relative;
        z-index: 100;

        @media(min-width: $screen-md-min ) {
            width: auto;
        }

        &.btn--start-tour {
            float: none;
            width: 100%;
            background: $c-brand-primary;
            pointer-events: none;
            padding-right: 15px;

            font-size:15px;
            margin-top: 10px;

            @media(min-width: $screen-lg-min){

                font-size:20px;
            }

            &:after,
            &:before {
                display: none;
            }

            @media(min-width: $screen-sm-min) {
                float: right;
            }
        }
    }

    #tooltip {
        opacity: 0;

        @media(min-width: $screen-md-min) {
            animation: heroToolTip ease-in 1.75s forwards;
            animation-delay: 1.5s;

            #toolTip__arrow {
                opacity: 0;
                animation: heroToolTip ease-in 1s forwards;
                animation-delay: 2s;
            }
        }
    }

     &__content {

        @media(min-width: $screen-md-min) {
            width: 50%;
        }
    }



    &__content{

        position:absolute;
        top:10px;
        background: $c-brand-primary;
        padding: 4px;
        color: #fff;
        top: 93px;
        width: 100%;
        z-index: 100;
        font-size: 13px;

        @media(min-width: $screen-md-min){

            top: 150px;
            width: 61%;
            z-index: 100;
            font-size: 13px;
            border-radius:1em;
        }

        @media(min-width: 1275px){

            width:100%;
            font-size:15px;
            padding:7px;
            left:0;
            top:150px;
        }
    }
}
