.footer{
	padding:20px;
	background:$c-brand-primary;
	font-size:17px;
	text-align:center;
	color:#fff;
	border-bottom:5px solid $c-yellow;
	position:relative;
	overflow: hidden;

	@media(min-width: $screen-md-min){

		padding:30px 60px 50px;
	}

	&:before{

		content:"";
		position:absolute;

		@extend %offSymbolDark;

		width:570px;
		height:auto;
		padding-top:100%;
		background-size:contain;
		background-repeat:no-repeat;
		opacity:0.1;
		left:0;
		top:-40px;

	}

	a{

		color:#fff;
		text-decoration:underline;
		transition:all ease-in 0.3s;

		&:hover{

			color:#fff;
			opacity:0.7;
		}
	}

	.btn--blue, 
	.btn--yellow{

    	margin-bottom:20px;
    	margin-top:20px;
    	text-decoration: none;
    }

	.container-fluid{

		padding-left:0;

		@media(min-width: $screen-md-min){

			padding-left:15px;
		}
	}

	ul{

		@media(min-width: $screen-md-min){

			margin-top:12px;
		}		
	}

	li{

		@media(min-width: $screen-md-min){

			display: inline-block;
		}

		a{

			color:$c-body-text;
			text-decoration:none;
			padding:10px;
			font-size: 16px;
    		font-weight: 500;
    		transition:all ease-in-out 0.3s;

    		&:hover{

    			color:$c-brand-primary;
    		}


		}
	}

	&__mainNav{


		@media(min-width: $screen-md-min){

			display:inline-block;
		}
		
	}

	&__mainNavList{

		margin-bottom: 0;
	}

	&__logo{
		
		text-align: center;
    	margin: 0 auto;
    	display: inline-block;
    	margin-bottom: 20px;

    	@media(min-width: $screen-md-min){


    	}

	}

	.img-responsive--slwp{

		@media(max-width: $screen-sm-max){

			max-width:60%;
			margin:auto;
		}
	}

	&__contentBar{

		@media(min-width: $screen-md-min){

			float:right;
		}
		
	}

	&__thanks{

		font-size: 56px;
    	font-weight: 700;

    	@media(min-width: $screen-md-min){

    		font-size: 64px;
    	}

		i{

			@extend %offSymbolWhite;
			background-repeat:no-repeat;
			background-size: cover;
			display:block;
			margin:0 auto;
			width: 87px;
    		height: 87px;
    
		}

		p{
			font-size:23px;
		}
	}
}