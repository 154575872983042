#cookie-bar {
    color: #fff;
    padding: 18px 0;
    z-index: 999999;
    position: fixed;
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    background-color: $c-brand-primary;

    @media(min-width: $screen-md-min){

        // top:0;
        // bottom:auto;
    }



    .cookie-bar-container{ 
        margin: 0 auto; 
        position: relative; 
        width:100%;
    }

    p {
        margin: 0;
        padding: 0;
        text-align: left;
        color: #fff;
        font-size:14px;
        font-weight:500;
        line-height:1.75em;
    }

    a {
        color: #fff;
        text-decoration: underline;
    }

    .cb-policy {
        text-decoration: underline;
    }

    .cookie-message {
        float: left;
        padding:10px;
        margin:0 auto;
        width:98%;
        border: 0px none transparent;
    }

    .close-btn {
        font-size: 0;
        text-decoration: none;
        
        &:before {
            content: "×";
            color: #fff;
            font: 42px/100% arial,sans-serif;
            text-decoration: none;
            position: absolute;
            right: 8px;
            top: -12px;
        }
    }
}


//If we are inside experience editor
        #scPageExtendersForm ~ #cookie-bar,
        #scCrossPiece ~ #cookie-bar,
        .scEnabledChrome ~ #cookie-bar{

            position:relative;
            display:none;
        }