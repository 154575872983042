body{

	//Fixed header
	padding-top:90px;

	    position: relative;
    	overflow-x: hidden;


	@media(min-width: $screen-md-min){

		padding-top: 138px;


	}

	&.emissionsPage{

		padding-top:130px; //additional button back

		@media(min-width: $screen-md-min){

			padding-top: 125px;
		}
	}
}


//Allowance of no bleed container before true mobile breakpoint - PG
.container,
.container-fluid {
  @media(max-width: $screen-lg) and (min-width: $screen-xs-min){
    width:100% !important; //override for a fluid feel 
  }

  @media(min-width: $screen-lg-min){

  	 padding-left:30px;
  	 padding-right:30px;
  }
}

//Ready for inclusion as a sitecore class for new sections
.noGutter{

	.row{

		[class^="col-"],
		[class*=" col-"]

		{
			padding-right: 0;
  			padding-left: 0;

		}

	}
}