.detailSection{
	position:relative;
	margin-bottom: 80px;

	&:before { 
	  display: block; 
	  content: " "; 
	  margin-top: -130px; 
	  height: 130px; 
	  visibility: hidden; 
	  pointer-events: none; //in case of overlap with content links
	  position: relative;
	  z-index: -1;

	  @media(min-width: $screen-md-min){

	  	margin-top: -120px;
    	height: 120px;
	  }

	  	@media(min-width: $screen-lg-min){
			
			margin-top: -170px; 
	  		height: 170px; 
	  	}
	}


	.container{ //abstract shapes
		
		position:relative;
		min-height: 488px;
		padding:0px;

		@media(min-width: $screen-md-min){

			padding:0 15px;
		}

		@media(min-width: $screen-md-min){

			min-height: 600px;

		}

		&:before{

			@media(min-width: 1100px){

				background-image: url('/Assets/images/diagonal-stripe.svg');
				content:"";
				position:absolute;
				margin: auto;
	    		display: block;
	    		background-repeat: no-repeat;
	    		background-size: cover;	    		
				width:100%;
				height:115%;
				top: 200px;
    			left: 200px;
			}	
						
	    	@media(min-width: $screen-lg-min){

	    		
	    		top: 223px;
	    		bottom: 0;
	    		left: 145px;
	    		
	    		
			}
			
		}
	
	}

	.col-md-6,
	.col-sm-6{

		position: initial; //allows travel of title out of col constraint

		@media(min-width: $screen-md-min){

			position:relative;
		}
	}

	&__topLine{

		@media(min-width:$screen-md-min){
			
			position:absolute;
		width:60%;
		height:9px;
		background: $c-brand-primary;
		border-radius:1em;
		top:80px;
		right:0;

		}

		
	}

	&__number{

		width: 54px;
	    line-height: 54px;
	    border-radius: 50%;
	    text-align: center;
	    font-size: 40px;
	    background:$c-brand-primary; //default
	    color:#fff;

	     @media(min-width: $screen-md-min){

	    	width: 78px;
		    line-height: 78px;
		    border-radius: 50%;
		    text-align: center;
		    font-size: 30px;

		    margin-left: 55px;
	    }

	    @media(min-width: $screen-lg-min){

		    margin-left: 0px;
	    }
		
	}

	&__image{		

		margin-left:-15px; //adjust against col padding
		margin-top: 20px;
		background: #fff;

		//Safari fix for rounded overflow
		-webkit-transform: translateZ(0);

		img{

			//Safari fix for rounded overflow			
			-webkit-transform: translateZ(0);
		}
		
		

		@media(min-width: $screen-md-min){
			
			margin-left:0;
			margin-top:20px;
			position: absolute;
	    	left: 16%;
	    	top: 54px;
	    	width:380px;
	    	height:380px;
	    	border-radius:100em;			
			overflow: hidden;
		}

		@media(min-width: 1100px){

			left:28%;
		}

		@media(min-width: $screen-lg-min){
			
			position: absolute;
	    	left: 21%;
	    	top: 90px;
			box-shadow: 0 0 0px 44px #fff, 0 0 0px 111px $c-brand-primary;

		}

		img,
		svg{

			width:100%;
			height:auto;
			
			@media(min-width: $screen-md-min){
				
				
				position:absolute;
				width:auto;
				height:100%;
				top:0;
				bottom:0;
				right:0;
				left: -20%;
				margin:auto;

			}

			@media(min-width: $screen-lg-min){
				
				
				position:absolute;
				width:auto;
				height:100%;
				top:0;
				bottom:0;
				right:0;
				left: -20%;
				margin:auto;

			}
						
		}
	}

	&__content{

		padding-right:50px; //compensates for side nav
		z-index: 10;

		@media(min-width: $screen-md-min){

			padding-left:60px;
		}

		h2{

			top: 15px;
    		font-size: 23px;
    		left: 96px;
    		position:absolute;



			@media(min-width: $screen-md-min){

				position: relative;
				font-size:39px;
				line-height: 2em;
				left:auto;
				top:auto;

				&.longCopyTitle{

    				font-size:36px;
    			}
			}

			@media(min-width: $screen-lg-min){

				font-size:46px;

				&.longCopyTitle{

    				font-size:46px;
    			}
			}
		}

		h3{

			margin-top:50px;
		}
	}

	//Colour Ways

	&--1,
	&--2,
	&--3{

		//Blue
		h2{

			color:$c-blue;
		}
		.detailSection__topLine,
		.detailSection__number{

			 background:$c-blue; 
		}
		

		.detailSection__image{

			@media(min-width: $screen-md-min){

				box-shadow: 0 0 0px 16px #fff, 0 0 0px 45px $c-blue;
			}

			@media(min-width: $screen-lg-min){

				box-shadow: 0 0 0px 44px #fff, 0 0 0px 111px $c-blue;
			}
		}
	}

	&--4,
	&--5,
	&--6,
	&--7,
	&--8{

		//Orange
		h2{

			color:$c-orange;
		}
		.detailSection__topLine,
		.detailSection__number{
			
			 background:$c-orange; 
		}

		.detailSection__image{

			@media(min-width: $screen-md-min){

				box-shadow: 0 0 0px 16px #fff, 0 0 0px 45px $c-orange;
			}

			@media(min-width: $screen-lg-min){

				box-shadow: 0 0 0px 44px #fff, 0 0 0px 111px $c-orange;
			}
		}
	}

	&--9,
	&--10,
	&--11,
	&--12{

		//Light blue
		h2{

			color:$c-brightblue;
		}
		.detailSection__topLine,
		.detailSection__number{
			
			 background:$c-brightblue; //default
		}

		.detailSection__image{

			@media(min-width: $screen-md-min){

				box-shadow: 0 0 0px 16px #fff, 0 0 0px 45px $c-brightblue;
			}

			@media(min-width: $screen-lg-min){

				box-shadow: 0 0 0px 44px #fff, 0 0 0px 111px $c-brightblue;
			}
		}
	}

	&--13,
	&--14,
	&--15{

		//Grey
		h2{

			color:$c-beige;
		}
		.detailSection__topLine,
		.detailSection__number{
			
			 background:$c-beige; //default
		}

		.detailSection__image{

			@media(min-width: $screen-md-min){

				box-shadow: 0 0 0px 16px #fff, 0 0 0px 45px $c-beige;
			}

			@media(min-width: $screen-lg-min){

				box-shadow: 0 0 0px 44px #fff, 0 0 0px 111px $c-beige;
			}
		}
	}

	
}

//Switch Columns around with overrides for desktop
.detailSection--reverse{

	@media(min-width: $screen-md-min){

		.col-md-6,
		.col-sm-6{
		
			float:right;
		}

		.container:before{

			transform: scaleX(-1);
			right:145px;
			left:auto;
		}

		.detailSection__number{		

			float:right;

			margin-right: 55px;
			margin-left:auto;
			
		}

		.detailSection__image{

			left:auto;
			right:16%;
		}

		.detailSection__topLine{

			left:30px;
			right:auto;
		}

		.detailSection__content{

			padding-left:0px;
		}

	}

	@media(min-width: 1100px){

		.detailSection__image{

			right:27%;
		}
		
	}

	@media(min-width: $screen-lg-min){

		.detailSection__image{

			margin-right:0;
		}
		
	}

}	

//transition animations

.detailSection{

	&__topLine{


	}

	
	&__image{

		img,
		svg{

			opacity:0.5;
			left:0; //image pan
		}
		
	}

	
	&__content{

		h2,h3,p,a,button{

			opacity:0.3;
		}
	}

	&__number{

		opacity:0;
		transform: scale(0);
	}

	&__topLine{

		width:0%;
	}

// Reverse

	&--reverse{


	}

// SVG Images

	#sectionImage-1{

		position:relative;

		#cloud{
			
			transform:translateX(-100px);
			animation: cloudPan 8s ease-in reverse;

		}

	}

// Active State

	&--active{

		
		.detailSection__image{

			img,
			svg{

				animation:fadeUpSection 0.3s ease-in-out forwards,
				imagePan 3s ease-in-out forwards;

			}
			
		}

		.detailSection__number{

			animation: bounceNumber 0.4s ease-out forwards;
			animation-delay:0.35s;
		}

		.detailSection__topLine{

			animation:topLine 0.3s ease-in forwards;
		}

		.detailSection__content{

			h2{

				animation:fadeUp 0.3s ease-in-out forwards;
				animation-delay:0.25s;
			}

			h3{
				
				animation:fadeUp 0.5s ease-in-out forwards;
				animation-delay:0.55s;

			}

			p,
			a{
				
				animation:fadeUp 0.7s ease-in-out forwards;
				animation-delay:0.75s;

			}

			

			.btn--photos{
				
				animation:fadeUp 0.8s ease-in-out forwards;
				animation-delay:1s;

			}

			.btn--topStep{

				animation:fadeUp 0.9s ease-in-out forwards;
				animation-delay:1.10s;
			}

			.btn--nextStep{
				
				animation:fadeUp 1s ease-in-out forwards;
				animation-delay:1.25s;

			}


		}


		//SVG Images

		#sectionImage-1{

			#cloud{
				
				animation: cloudPan 8s ease-in forwards;

			}

		}

	}//--active

	.slick-slide{

		img{
			transition:all ease-in 0.3s;
			width:100% !important;
		}
	}
	
}

//Outliers

.detailSection--6{

	.container{

		&:before{

			@media(min-width: $screen-md-min){
			
				right:119px;
				height:90%;
			}

			@media(min-width: $screen-lg-min){
			
				right:250px;
				height:110%;
			}
		}
	}	
}