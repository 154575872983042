//
// Base -> Typography
//
// Use this file for site-wide typographic
// adjustments, including web fonts and headings.
// --------------------------------------------------
html {
    /* Adjust font size */
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  }

body{

    font-size:16px;
    font-weight: 600;
    color:$c-body-text;

}

.h2, h2,.h2, h3,.h3, h4, .h4, h5, .h5 {
    margin-top: 0;
    margin-bottom: 25px;
    font-family:$font-family-sans-serif;
    font-weight:900;

}

h1,.h1 {

    margin-bottom: 22px;
    color:$c-brand-primary;

    @media (min-width: $screen-md-min) {
        font-size: 72px;
        color:$c-brand-primary;
        margin-bottom: 50px;
        line-height:1.5em;
    }
}

h2, .h2 {
    font-size: 24px;
    @media (min-width: $screen-md-min) {
        font-size: 50px;
        line-height: 1.15em;
    }
}
h3, .h3 {
    font-size: 26px;
    color:$c-brand-primary;
}

h4, .h4 {

    font-size:17px;

    @media (min-width: $screen-md-min) {
        font-size: 18px;
        line-height: 23px;
    }
}

h5, .h5 {
    @media (min-width: $screen-md-min) {
        font-size: 16px;
        line-height: 20px;
    }
}

p { 

    margin-bottom: 15px; 
}

select, input {
    -webkit-border-radius: 0;
}

.text-center-xs {
    @media (max-width: $screen-sm-max) {
        text-align: center;
    }
}

.bodyFont{

    font-family:$font-family-sans-serif;
}

a{

    color: $c-brand-primary;
    text-decoration: underline;
    transition:color 0.3s ease-in-out;

    &:hover{

        
    }
}

.emissionsPage{

    h1{
        @media (min-width: $screen-md-min) {
           font-size:55px; 
       }        
    }

    h2{

        color:$c-brightblue;
    }
}