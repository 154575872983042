
//Stops annoying jog to other content
body.modal-open[style] {
    padding-right: 0px !important;
}
.modal {
    overflow-y: auto;
}

.modal-open {
    overflow: auto !important;
}

.modal {
    &-backdrop.in {
        opacity: 0.85;
    }
    &-dialog {
        @media (min-width: $screen-sm-min) {
            margin-top: 100px;
        }
    }

    &.is-active { display: block; }

    &__inner {
        background: white;
        position: relative;
        padding: 30px;
    }

    &__actions {
        text-align: center;
        display: block;
        margin: 25px auto 0;

        @media (max-width: $screen-sm-max) {
            .accept {
                margin-bottom: 10px;
            }
        }
    }

    &--active {
        &:before {
            background-color: rgba(0,0,0,0.87);
            content: "";
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 100;
        }
    }
}

//Bootstrap overrides
.modal-backdrop {

  // position: relative;
  display:none;
  
}

.modal-open .modal {

    z-index: 9999;
}

.modal {
  background: rgba(255,255,255,0.8);
  overflow:inherit;

  &.in{

    +.youtubeVideoOverlay{

        width:10px;
    }
  }

  
}

.modal-header{

    border-bottom:0px none;

    .close{


        font-size: 65px;
        color: $c-brand-primary;
        background: transparent;
        opacity: 1;
    }
}

.modal-dialog{

    @media(min-width: $screen-md-min){

        width:800px;
        overflow: hidden;
    }
}

.modal-content{

    border:none;
    box-shadow:0px 0px 0px transparent;
    background:#fff;


    .video{

        filter: drop-shadow(5px 5px 0px #000) blur(0); //Used instead of box shadow
    }
}

.modal-title{

    font-size:27px;
    color:$c-brand-primary;
}

.modal-body--info{

    padding:30px;
    background:#fff;

    h1{
            font-size:50px;
            color:$c-brightblue;
            font-weight:bold;

    }

    h2{
            font-size:40px;
            color:$c-brand-primary; 
    }
}