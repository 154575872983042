﻿// Linear-gradient
// @param {Keyword | Angle} $direction - Linear gradient direction
// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}

@mixin background-lined-gradient($topColour) {
    $darker: darken($topColour, 1%);
    $darkest: darken($topColour, 4%);
    background: linear-gradient(149deg, $topColour 0%, $topColour 50%, $darker 50.2%, $darkest 100% );
}

@mixin clearfix-micro() {
    & {
        *zoom: 1;
    }
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// Material Icon
// JA Copyright 2018
// This includes the related styles needed to pull in a material-icons
// Usage: @include material_icon("close", 32px);
// @param $iconName - The matching material-icon name used as the font character - required
// @param $fontSize - The desired fontsize of the icon, default: 16px - optional

@mixin material_icon($iconName, $fontSize: map-deep-get($fonts, base, p)) {
    font-family: "Material Icons";
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga' 1;
    font-style: normal;
    text-transform: none;
    line-height: 1;
    content: $iconName;
    font-size: $fontSize;
}

// Font Awesome
// Requires FontAwesome to be included in the project
// @param $iconName - The matching suffix of the desired icon - required
// @param $fontSize - The desired fontsize of the icon, default: 16px - optional
// @param $fontType - This indicates which font set to use, default: free - free/brand

@mixin fa-icon($iconName, $fontSize: 16px, $fontType: "free") {
    &:before { 
      font-style: normal;
      font-size: $fontSize;
      @if $fontType == 'free' {
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        font-style: normal;
      } @else if $fontType == 'brands'{
         font-family: "Font Awesome 5 Brands";
         font-style:normal;
      }
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      font-variant: normal;
      line-height: 1;
      content: fa-content($iconName);  
    }
}


// Usage: @include aspect-ratio(400,300)
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

//handy wrapper to constrain images within a fixed height box
@mixin image-wrapper-height($height){
    height:$height;
    overflow:hidden;
    position:relative;
    > img {
        position:relative;
        transform: translateY(-50%);
        top: 50%;
        width: 100%;
        height: auto;
    }
}

@mixin image-wrapper-width($width){
    width:$width;
    overflow:hidden;
    position:relative;
    > img {
        position:relative;
        height: 100%;

        top: 50%;
        left:0;
        right:0;
        width: 100%;
        height: auto;
    }
}
//Handles the switching of the two colour svg states in
    @mixin icon-image-path($name, $name-colour){

      $image-path: '/assets/images/' !default;

      i,em{
        background-image: url(#{$image-path}/#{$name}.svg);
        // transition:all ease-in-out 0.3s;
      }
      
      &.active,
      &:hover{

        i,em{
          background-image: url(#{$image-path}/#{$name-colour}.svg);
        }
      }
}

@mixin chevron($size, $thickness, $colour, $back-colour, $direction: 'up'){
    position:relative;
    display:inline-block;
    height:$size*2;/*height should be double border*/

    &:before,
    &:after{

      position:absolute;
      display:block;
      content:"";
      border:$size solid transparent;/*adjust size*/

    }

    &:before{
  
      top:0;
      border-top-color:$colour;/*Chevron Colour*/

    }

    &:after{

      top:-$thickness;/*adjust thickness*/
      border-top-color:$back-colour;/*Match background colour*/
    }

     @if $direction == 'down' {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      } @else if $direction == 'left' {
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
      } @else if $direction == 'right' {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }

}

@mixin triangle($direction, $sizeH, $sizeV, $color){
    content:'';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    -moz-transform: scale(.9999);
      
  @if $direction == top {
    border-width: 0 $sizeV $sizeH $sizeV;
    border-color: transparent transparent $color transparent;
  }
  @if $direction == bottom{
    border-width: $sizeV $sizeH 0 $sizeV;
    border-color: $color transparent transparent transparent;
  }
  @if $direction == left{
    border-width: $sizeV $sizeH $sizeV 0;
    border-color: transparent $color transparent transparent;
  }
  @if $direction == right{
    border-width: $sizeV 0 $sizeV $sizeH;
    border-color: transparent transparent transparent $color;
  }
  @if $direction == topright{
    border-width: 0 $sizeH $sizeV 0;
    border-color: transparent $color transparent transparent;
  }
  @if $direction == bottomright{
    border-width: 0 0 $sizeH $sizeV;
    border-color: transparent transparent $color transparent;
  }
  @if $direction == bottomleft{
    border-width: $sizeH 0 0 $sizeV;
    border-color: transparent transparent transparent $color;
  }
  @if $direction == topleft{
    border-width: $sizeH $sizeV 0 0;
    border-color: $color transparent transparent transparent;
  }
}

@mixin angleBoxSmall($c-background-no-hash){

  background-image: url("data:image/svg+xml,%3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='431' height='269' viewBox='0 0 431 269'%3E%3Ctitle%3EArtboard 6%3C/title%3E%3Cpath fill='%23 + $c-background-no-hash +' d='M5 6l16 258 390-5 16-242L5 6z'/%3E%3C/svg%3E");
}

@mixin angleBoxMed($c-background-no-hash){

  background-image: url("data:image/svg+xml,%3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='91' height='92' viewBox='0 0 91 92'%3E%3Ctitle%3EArtboard 1%3C/title%3E%3Cpath fill='%23 + $c-background-no-hash +' d='M12 81L3 20 88 5l-5 82-71-6z'/%3E%3C/svg%3E");
}

@mixin angleBoxLarge($c-background-no-hash){

  background-image: url("data:image/svg+xml,%3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='431' height='269' viewBox='0 0 431 269'%3E%3Ctitle%3EArtboard 6%3C/title%3E%3Cpath fill='%23 + $c-background-no-hash +' d='M5 6l16 258 390-5 16-242L5 6z'/%3E%3C/svg%3E");
}



/// IE specific dropshadows
@mixin ieButtonShadow{
            
            // uses %buttonBackShadow;  
            background-image: url("data:image/svg+xml,%3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='248' height='78'%3E%3Cpath fill='%23000000' d='M233.78 73.49h-220L6 18.49l234.78-14-7 69z'/%3E%3C/svg%3E");  
            content:"";
            position:absolute;
            background-repeat:no-repeat;
            background-size: 100%; // responsive cover
            background-position:100%;
            width:100%;
            height:110%;
            z-index:-2;
            top:0;
            right:-15px;
            bottom:-23px;
            left:0;
            margin:0 auto;

}