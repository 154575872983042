
// Hamburger vertical alignment
$hamburger-first-bar: 0px;
$hamburger-second-bar: 12px;
$hamburger-third-bar: $hamburger-second-bar * 2;

$c-hamburger-bar-colour:grey;


.hamburgerToggle {
    float: right;
    background-color: transparent;
    border-radius: 0;
    margin: 36px 15px 0 0;
    position: relative;
    height: 63px;
    width: 40px;
    padding-bottom: 0;
    z-index: 999;

    margin: 23px 30px 0px 0px;

    @media(min-width: $screen-md-min){

        margin: 60px 30px 0px 0px;
    }

    @media (min-width: $mobile-toggle-max + 1) {
        display: none;
    }
        
    &__icon {
        width: 63px;
        height: 40px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        right:20px;
        left:0;

        span {
            left:0;
            right:0;
            margin:0 auto;
            display: block;
            text-align:center;
            position: absolute;
            height: 4px;
            width: 37px;
            border-radius: 1em;
            background: $c-brand-primary;
            opacity: 1;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
        }

        p {
            color: #fff;
            margin-top: 15px;
            font-weight: 700;
            font-size: 11px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 2px;
            text-align: center;
            //prevents font-blurring movement
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0) scale(1.0, 1.0);
            transform: translateZ(0);
        }

        span:nth-child(1){
            top: $hamburger-first-bar; 
        }
        span:nth-child(2), 
        span:nth-child(3){ 
            top: $hamburger-second-bar; 
        }
        span:nth-child(4){ 
            top: $hamburger-third-bar; 
        }

        &.icon-change {
            span:nth-child(1),
            span:nth-child(4){
                top: $hamburger-second-bar;
                width: 0%;
                left: 50%;
            }
            span:nth-child(2){
                transform: rotate(45deg); 
            }
            span:nth-child(3){ 
                transform: rotate(-45deg);
            }
            span:nth-child(2),
            span:nth-child(3){
                background: $c-brand-primary;
            }
        }
    }
}

.menuText{

    display:none;
}

