.back-to-top {
    cursor: pointer;

    z-index: 10000;
    bottom: 60px;
    right: 50px;
    background:$c-brand-primary;
    background-size:cover;


    display:none;
    position: fixed;
    padding: 20px;
    transition:all ease-in-out 0.3s;

    &:before{

        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: inline-block;
        height: 15px;
        left: 3px;
        position: relative;
        top: 10px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        vertical-align: top;
        width: 18px;
        border-color: #fff;

    }

    @media (min-width: $screen-sm-min) {
        bottom: 45px;
        right: 45px;
    }
    
}