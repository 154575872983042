.emissionsList{
	
	padding:20px;
	padding-left:60px;
	border:4px solid $c-brand-primary;
	margin:20px 0;
	border-radius:1em;

	li{

		margin-bottom:5px;
	}
}

.emissionsPage{

	&__listTitle{

		margin-top:60px;
	}

	a{

		&:hover{

			color:$c-brand-primary;
			opacity: 0.7;
		}
	}

	.btn{

		&:hover{

			color:#fff;
		}
	}
}