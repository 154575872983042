//
// Base -> Variables
//
// Use this file to customise Bootstrap variables
// ahead of compilation.
// (See vendor/bootstrap/_variables.scss)
// --------------------------------------------------

// Colour Palette Colours
// define with $c-<colourname>
// Example: $c-body: grey;

$c-body-text: #7c8988;

//Brand
$c-brand-primary: #009a44; //Viridor Green
$c-brand-dark-grey:#435563; // Dark Grey Blue

//Palette
$c-blue: #004ea8;
$c-brightblue: #00b8d4;
$c-grey:#7a99ac;
$c-skyblue:#b8d9f2;
$c-yellow:#fcaf17;
$c-orange:#f36f21;
$c-beige:#b6aea5;

//Buttons

$c-button-primary:$c-brand-primary;

//Backgrounds

$c-back-lightgrey:#f2f4fb;
$c-back-midgrey:#d8dae0;

// Bootstrap resets
$font-family-sans-serif: 'Roboto', sans-serif;;
$font-size-base: 16px;
$headings-font-family: $font-family-sans-serif;

// Reset the mobile menu breakpoint
$grid-float-breakpoint: 992px;

$c-lightgrey: #e4e4e4;

//Container
$grid-gutter-width:         30px !default; // Not override but we need this for other references
$container-large-desktop:      (1319px + $grid-gutter-width);
$screen-lg: 1366px;