
//Width to show flyout 
$mobile-toggle-min: 404px; 
$mobile-toggle-max:2000px; // not needed as always hamburger
$desktop-menu-min: 20000px;

// Calc to pull out 
$nav-side-width: 450px;
$nav-slide-start: -425px; //allows peek
$nav-slide-end: 266px;

$nav-mid-side-width: 450px;
$nav-mid-slide-start: -400px; //allows peek
$nav-mid-slide-end: 350px;

$nav-desk-side-width: 450px;
$nav-desk-slide-start: -370px; //allows peek
$nav-desk-slide-end: 350px;


//Top Menu Desktop Link Style
$text-transform: normal;
$top-menu-font-weight:600;
$top-menu-colour:$c-body-text;
$top-font-size:21px;
$top-hover-colour: $c-body-text;
$c-desk-active-colour: $c-brand-primary;
$link-padding:10px 19px;

//Desktop Dropdown Background & Link Style
$dropdown-menu-colour:$c-body-text;
$dropdown-font-size:18px;
$dropdown-hover-colour:$top-hover-colour;
$c-desk-dropdown: #fff; 


//Desktop Chevron Dropdown
$c-dropdown-link:$c-brand-primary;
$c-chevron-colour:$c-brand-primary;
$c-chevron-background:#fff;
$chevron-width:10px;
$chevron-depth:2px;

// Example of chevron include below
// @include chevron($chevron-width, $chevron-depth, $c-chevron-colour,$c-chevron-background, 'up');



//Mobile Menu Link Style
$c-mobile-link-colour:#fff;
$mobile-font-size:15px;

//Mobile Flyout Background
$c-mobile-flyout-colour:$c-brand-primary;



//Show or hide flyout elements
.flyout__visible, 
.flyout__hide{

    display:none;
}

@media (max-width: $mobile-toggle-max) {

    .flyout__visible{
        display:block;
    }

    .flyout__hide{
        display:none !important;
    }

}

@media (min-width: $desktop-menu-min) {

    .flyout__visible{
        display:none !important;
    }

    .flyout__hide{
        display:block;
    }

}

.flyout__visible--sm-md {
    display: none !important;
}

.flyout__hidden--sm-md {
    display: block !important;
}
@media (min-width: $screen-sm-min) and (max-width: 1200px) {
    
    .flyout__visible--sm-md {
        display: block !important;
    }
    
    .flyout__hidden--sm-md {
        display: none !important
    }
}
/////////////////////////////////////////////////////////////////////

.nav{

    //link overrides
    a:visited { text-decoration: none; color:#fff; }
    a:hover { text-decoration: none; color:#fff; }
    a:focus { text-decoration: none; color:#fff; }
    a:hover, a:active { text-decoration: none; color:#fff; }

        
    
        display: inline-block;
        width: $nav-side-width;
        position: absolute;
        top: 96px;
        padding: 13px 4px;
        right: $nav-slide-start;
        bottom: 0;
        z-index: 1000;
        transition: transform .6s;
        background: #fff;
        height:100vh;
        box-shadow: -2px 7px 12px 3px rgba(0, 0, 0, 0.1);
        border-radius:1em 0 0 1em;        
        height:100vh;

        @media(min-width: $screen-md-min){

           width: $nav-mid-side-width; 
           right: $nav-mid-slide-start;
           top: 134px;
           padding:20px 0;
           box-shadow: 0px 0px 0px 0px transparent;

        }

        @media(min-width: $screen-lg-min){

           width: $nav-desk-side-width; 
           right: $nav-desk-slide-start;
           top: 150px;
           padding:20px 0;
        }
        
        
    
    //slide pullout
    &__slideIcon{

        position:absolute;
        height:100%;
        z-index: 10;
        left:-1px;
        padding-right:15px !important;

        @media(min-width: $screen-md-min){

            left:-15px; //moves out over the green tab
            width:20px;
            height:160px; 
            margin-top: 50%;
            z-index: 1;

        }
        
        @include fa-icon($fa-var-play, 7px);
        &:before{

            z-index:2;
            top: 48%;
            left: -8px;
            position:absolute;
            color:#fff;
            transform: rotate(180deg);

            @media(min-width: $screen-md-min){

                left:4px;
            }
        }

        &:after{    
            content:"";
            background:$c-brand-primary;            
            width:16px;
            height:160px;
            position:absolute;
            top:36%;
            left: -15px;
            z-index: -1;
            border-radius:1em 0 0 1em;
            text-align:center;
            line-height: 160px;
            
            padding:0 !important;
            cursor:pointer;
            z-index:-1;  

            @media(min-width: $screen-md-min){

                left:0; //click zone moves out
                top:0;
            }  

        }

        @media(min-width: $screen-md-min){

               @include fa-icon($fa-var-play, 7px);

               
            }

            @media(min-width: $screen-lg-min){

               @include fa-icon($fa-var-play, 13px);

            }

    }
        
}

// General Mobile Elements Styling
.nav__list{
               
            margin:0px;
            width: $nav-side-width;
            display: block;
            padding: 0 0 100px 0;
            list-style-type: none !important;
            list-style: none !important; 
            list-style-image:none;
            background:#fff;  

            overflow-y:scroll;
            height:100%;
            margin-bottom:100px; //ie fix

            @media(min-width: $screen-md-min){

                width: $nav-mid-side-width; 
            }

            @media(min-width: $screen-lg-min){

                width: $nav-desk-side-width; 
            }
}


.nav__item{

    // width: $nav-side-width;
    margin-right: 0;
    list-style-type: none !important;
    list-style: none !important; //IE Edge flashes list fix
    list-style-image:none;

}

.nav__link{

    color:$c-mobile-link-colour;
    background:#fff; //default until active
    display: block;
    font-weight:200;
    text-align: left;
    padding:3px;
    
    margin-bottom:7px;
    border-radius:2em;
    position:relative;
    cursor: pointer;
    font-size: $mobile-font-size;
    font-weight: $top-menu-font-weight;
    text-decoration:none;
    transition:background ease-in-out 0.3s;

    @media(min-width: $screen-md-min){

        padding: 3px 10px 3px 3px;
        font-size:$top-font-size;
    }

    @media(min-width: $screen-lg-min){

        padding: 3px 20px 3px 3px;
        font-size:$top-font-size;
    }

    &:hover{

        text-decoration:none;
    }  

    i{ //number circle
        
        width: 12px;
        line-height: 8px;
        border-radius: 50%;
        text-align: center;
        font-size: 0px;
        background-color:#fff; //default until active
        border:2px solid $c-brand-primary; //default
        color: #fff;
        font-style: normal;
        display: inline-block;
        margin-right:70px;
        transition:background-color ease-in-out 0.3s, color ease-in-out 0.3s;

        @media(min-width: $screen-md-min){

            font-size: 12px;
            width: 18px;
            line-height: 12px;
            border:3px solid $c-brand-primary; //default
        }

        @media(min-width: $screen-lg-min){

            font-size: 16px;
            width: 26px;
            line-height: 20px;
            border:3px solid $c-brand-primary; //default
        }
    }

    //Variants

    //--home does not have the nav__link class as this is cycled with JS
    @at-root #{&}--home {
    @extend .nav__link;    

        i{

            
            border:none;
            border-radius:0;            
            @extend %homeIconGreen;
            background-repeat:no-repeat;
            background-size: cover;
            background-position: -3px;
            display:inline-block;
            width: 13px;
            height: 14px;           
            text-indent:-999em; 

            @media(min-width: $screen-md-min){

                width: 20px;
                height: 20px;
            }

            @media(min-width: $screen-lg-min){

                width: 36px;
                height: 36px;
            }
            
        }
    }

    &--thanks{

        margin-bottom:30px;

        i{
            border:none;
            border-radius:0; 
            // @extend %offSymbolDark;
            background-image:url('/Assets/images/off-logo-dark.svg');
            line-height:40px;
            background-repeat:no-repeat;
            background-size: cover;
            display:inline-block;
            width: 14px;
            height: 14px;  

            @media(min-width: $screen-lg-min){

                width: 20px;
                height: 20px; 
            }

            @media(min-width: $screen-lg-min){

                width: 30px;
                height: 30px; 
            }
        }
    }

//Active Section

    &--active{

        i{

            background:$c-brand-primary; //default
            color:#fff;

            @media(min-width: $screen-md-min){

                font-size:15px;
                width: 25px;
                line-height: 20px;
            }

            @media(min-width: $screen-lg-min){

                font-size:17px;
                width: 30px;
                line-height: 24px;
            }


        }
    }

//Colourways

    &--1,
    &--2,
    &--3{

        i{
            border-color:$c-blue;
        }

        &.nav__link--active{

            i{
                background:$c-blue;
            }
        }

    }

    &--4,
    &--5,
    &--6,
    &--7,
    &--8{
                 
        i{
            border-color:$c-orange;
        }

        &.nav__link--active{

            i{
                background:$c-orange;
            }
        }

    }

    &--9,
    &--10,
    &--11,
    &--12{


        i{
            border-color:$c-brightblue;
        }

        &.nav__link--active{

            i{
                background:$c-brightblue;
            }
        }


    }

    &--13,
    &--14,
    &--15{


        i{
            border-color:$c-beige;
        }

        &.nav__link--active{

            i{
                background:$c-beige;
            }
        }

    }
   
}



.nav__back{

    color:$c-brand-primary;
        
}

.nav__mobileParentTitle{
  
    margin-left:0px;
    padding-left:0px;
}

/// Mobile panel slide behaviour if children
.is-dropdown,
    .flyout{

        > .nav__list{
            // width: $nav-side-width;           
            z-index: 1000;
            transition: transform .6s;
            margin: 0;
            display: block;
            padding: 0 0px 100px;
            margin-bottom:100px;

            @media(min-width: $screen-md-min){

                padding: 0 10px 100px;

            }

            @media(min-width: $screen-lg-min){

                padding: 0 20px 100px;

            }

            
        }

        &.is-active{

            .nav__slideIcon:before{

                transform: rotate(0deg);
            }


        }

        &.is-active > .nav__list{

            padding: 0 10px 100px;

            @media(min-width: $screen-lg-min){

                padding: 0 20px 100px;

            }
            
            display: block;

            .nav__item{

                animation: fadeUp ease-in-out 0.4s forwards;
                opacity:0;

                // Loop through the items and add some delay.
                    @for $i from 1 to 20 {
                        &:nth-child(#{$i}) { 
                            animation-delay: $i * 0.03s; 
                        }
                    }
            }
            
            .nav__link--home,
            .nav__link{

                background:$c-brand-primary; //default

                i{
                
                    color:$c-brand-primary;
                    border-color:#fff;
                    width: 26px;
                    line-height: 22px;
                    margin-right: 10px;
                    font-size: 15px;

                    @media(min-width: $screen-md-min){
                        
                        width:33px;
                        line-height:27px;
                        margin-right:15px;
                        font-size: 18px;

                    }

                    @media(min-width: $screen-lg-min){
                        
                        width:40px;
                        line-height:32px;
                        margin-right:15px;
                        font-size: 23px;

                    }

                }



                &:hover{

                    color:#fff !important;
                    opacity:0.8;
                }
                &:active, &:focus{

                    color:#fff !important;
                    text-decoration: none;
                }

            //Variant
                &--home{

                    i{

                        background-color: transparent;
                        @extend %homeIconWhite;

                            background-position: 3px;
                            height:23px;

                             @media(min-width: $screen-lg-min){

                                height:28px;
                            }

                            @media(min-width: $screen-lg-min){

                                height:33px;
                            }
                    }
                }

                 &--thanks{

                        padding-left: 39px;
                        height: 40px;
                        padding-top: 10px;

                        @media(min-width: $screen-md-min){

                            padding-top:6px;
                            padding-left:50px;
                        }

                    i{ 

                        // background: transparent;
                        // @include fa-icon($fa-var-smile, 24px);
                        // color:#fff;
                        // height:23px;

                        //     @media(min-width: $screen-md-min){

                        //         height:30px;
                        //         @include fa-icon($fa-var-smile, 30px);
                        //     }

                        //     @media(min-width: $screen-lg-min){

                        //         height:33px;
                        //         @include fa-icon($fa-var-smile, 32px);
                        //     }

                        background-image:url('/Assets/images/off-logo-white.svg');
                        background-color:transparent;                        
                        margin-top:5px;
                        background-position: 50%;
                        position: absolute;
                        left: 6px;
                        top: 3px;

                        width:23px;
                        height:23px;
                    }
                }

            //Colourways

                &--1,
                &--2,
                &--3{

                    //Blue
                    background:$c-blue;

                    i{
                        color:$c-blue;
                    }

                }

                &--4,
                &--5,
                &--6,
                &--7,
                &--8{
                
                    //Orange
                    background:$c-orange;

                    i{
                        color:$c-orange;
                    }

                }

                &--9,
                &--10,
                &--11,
                &--12{

                    //Light blue
                    background:$c-brightblue;

                    i{
                        color:$c-brightblue;
                    }


                }

                &--13,
                &--14,
                &--15{

                    //Grey   
                    background:$c-beige;

                    i{
                        color:$c-beige;
                    }

                }

                &.nav__link--active{

                    i{
                        color:#fff;
                    }
                }
            }
         
        }
                              
    }


//// Active state change
.nav.is-active{

        transform: translateX( -$nav-slide-end);
        width: $nav-side-width;

        @media(min-width: $screen-md-min){
            
           transform: translateX( -$nav-mid-slide-end);
           width: $nav-mid-side-width; 

           @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            // IE10+ Allows for scroll, so nav is accessible in ie - RM
            overflow-x: scroll;
            }
        }

        @media(min-width: $screen-lg-min){
            
           transform: translateX( -$nav-desk-slide-end);
           width: $nav-desk-side-width; 

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            // IE10+ Allows for scroll, so nav is accessible in ie - RM
            overflow-x: scroll;
            }
        }
        

        .nav__overlay {
                width: 100%;
                opacity: 1;
                transition: width 0s, opacity .3s;
                height: 100vh;
                z-index: -1;
            }

}


.nav__item--current-section{

    .is-active{

        > span{

            color:$top-hover-colour;
        }
    }
}



.nav__overlay{

    width: 0;
    overflow: hidden;
    background-color: $c-mobile-flyout-colour;
    position: absolute;
    opacity: 0;
    bottom: 0;
    top:0;
    transition: width 0s ease 0.3s, opacity 0.3s;
    height: 100vh;


}

.nav__item--tabletSwapSpan{

    display:none;
}

///Mobile Toggle (replaces hamburger for this project)



.mobileNav__toggle{  

    padding:14px 20px;

    .menuText{

        color:$c-brand-primary;
        font-size:15px;      

    }

    i{
            margin-left:10px;
            top: 4px;

               @include chevron(5px, 1.5px, $c-chevron-colour,$c-chevron-background, 'up');  
    }

    //change state
    &.icon-change{

         i{
                margin-left:10px;
                top:-4px;
               @include chevron(5px, 1.5px, $c-chevron-colour, $c-chevron-background, 'down');  
        }

    }
}    

@supports (-ms-ime-align:auto){  
   /* IEEdge+ specific styles go here */  
   .nav{

       overflow-y:auto;
    }

    .nav__slideIcon{

        display:none;
    }
}