.header{

	position: fixed;
    width: 100%;
    top:0;
    left:0;
    right:0;
    z-index: 999;
    background:#fff;
    margin-bottom:20px;
    -webkit-box-shadow: 0px 4px 2px 1px rgba(186, 186, 186, 0.23137254901960785);
    -moz-box-shadow: 0px 4px 2px 1px rgba(186, 186, 186, 0.23137254901960785);
    box-shadow: 0px 4px 2px 1px rgba(186, 186, 186, 0.23137254901960785);
	&__logo{

		
		display:block;
		width:100px;
		height:auto;
		margin-top:0px;
		position:relative;

		@media(min-width: $screen-md-min){

			width:159px;
		}

		img{

			width:100%;
			max-width:auto;
		}

		
	}

	&__mainNav{

		@media(min-width: $screen-md-min){

			margin-top:30px;
			display:inline-block;

		}
	}

	//Changes the SWLP logo for sizing
	$logo-media-size: 520px;

	&__partner{

		margin-top:8px;
		text-align: center;

		@media(min-width: $logo-media-size){

			margin-top:20px;

		}

		@media(min-width: $screen-lg-min){

			margin-top:27px;
			text-align: left;
		}
		
		span, 
		img{
			display:inline-block;
		}

		span{

			font-weight:900;
			font-size:20px;
			color:$c-skyblue;
			margin-right: 23px;

			display:none;

			@media(min-width:$screen-md-min){

				display:inline-block;
				vertical-align: middle;
			}
		}

		

		img{

			max-width:75%;
			height:auto;

			@media(min-width: $logo-media-size){

				max-width: 250px; //swap out of square logo
			}

			@media(min-width: $screen-md-min){

				max-width:60%;
			}

		}

		//Quick swap out - return with srcset although this will require further cross browser support etc. -PG

		.partnerLogoSquare{

			display:inline-block;
            
			@media(min-width: $logo-media-size){

				display: none;
			}
		}

		.partnerLogo{

			display:none;

			@media(min-width: $logo-media-size){
                width: 100%;
				display: inline-block;
			}
		}

	}

	&__buttons{

		display:none;

		@media(min-width:$screen-md-min){

			display:inline-block;
			margin-top:45px;

			.btn{

				padding: 7px 9px;
				margin-right:5px;
				font-size:16px;
				

				@media(min-width: $screen-lg-min){

					padding:13px 15px;
					margin-right:12px;
					font-size:20px;
					float:right;

				}
				

				@media(min-width: 1500px){

					// width:26%;
				}
			}
		}

		
	}

	&__buttonReturn{

		@media(min-width:$screen-md-min){

			margin-top:45px;

			.btn--return{

				float:right;
			}
		}

		
	}


}


//Home Page

.home{

	.header__logo{

		@media(min-width: $screen-md-min){
			

			&:after{

				content:"";

				position:absolute;
				width:177px;
				height:8px;
				background:$c-brand-primary;
				bottom:-26px;

			}

		}
		
	}

}