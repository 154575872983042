@keyframes fadeUp{

	0%{

		opacity:0.3;
	}

	100%{

		opacity:1;
	}
}

@keyframes menuFade{

	0%{

		opacity:0;
	}

	100%{

		opacity:1;
	}
}

@keyframes topLine{

	0%{
		
		width:0%;
	}

	100%{

		width:60%;
	}
}

@keyframes bounceNumber{

	0%{
		
		transform:scale(0);
		opacity:0;
	}

	60%{

		transform:scale(1.1);

	}

	100%{

		transform:scale(1);
		opacity:1;
	}

}

@keyframes imagePan{
	
	0%{
		
		left:0;
		
	}

	100%{
		
		
		left:-20%;
		
	}

}

@keyframes cloudPan{
	
	0%{
		
		transform: translateX(-100px);
		
	}

	100%{
		
		
		transform: translateX(200px);
		
	}

}

@keyframes fadeUpSection{

	0%{

		opacity:0.5;
	}

	100%{

		opacity:1;
	}
}

@keyframes buttonPulse{

	0%{

		transform:scale();

	}

	100%{


	}
}

@keyframes heroImageMovement{

	0%{
		
		transform: translateX(1000px);
		
	}

	80%{

		transform: translateX(-100px);
	}

	100%{

		transform: translateX(0px);

	}
}

@keyframes heroToolTip{


	0%{
		opacity:0;
	}

	100%{

		opacity:1;
	}
}

@keyframes toolTipArrow{


	0%{
		opacity:0;
		transform:scale(0);
	}
	33%{

		transform: scale(1.3);
	}

	100%{

		opacity:1;
		transform:scale(1);
	}
}